<template>
  <div class="download nav-spcr wrapper page">
    <div class="row justify-content-center">
      <div class="col-12 col-md-7 col-lg-6 d-lg-flex align-items-center">
        <div class="text-center text-lg-left instructions">
          <h1 class="display-1 mt-3 mt-lg-5 font-weight-heavy">Available Now</h1>
          <p class="text-gray-light mt-3">SYPHER is only available for iOS devices (iPhones & iPads)</p>
          <p class="text-gray-light mt-3 small">We recommend you make a <a class="text-green" href="https://www.spotify.com/us/">Spotify</a> account if you don't already have a Spotify account for the best experince.</p>

          <div class="row mt-lg-5">
            <div class="col-12 col-lg-6">
             <a target="_blank" href="https://apps.apple.com/us/app/sypher-music/id1374755518"><img class="appstore" src="../assets/icons/appstore-badge.svg" alt="Download on the App Store"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7 col-lg-6 px-0">
        <img src="../assets/double.png" alt="test flight email" class="email-pic w-100 mt-5 mb-5 d-lg-none">
        <img src="../assets/double.png" alt="test flight email" class="email-pic d-none d-lg-block mx-auto">
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'download',
  data: function () {
    return {
      errors: [],
      email: null,
      // eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      sucessfullyInvited: false
    }
  },
  methods: {
    checkForm: function () {
      this.errors = [];
      if (!this.email) {
        this.errors.push('Please enter email.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }

      if (!this.errors.length) {
        axios({
            method: 'post',
            url: 'https://us-central1-sypher-landing.cloudfunctions.net/earlyaccess',
            data: {
              email: this.email
            }
          })
          .then((response) => {
            if(response.status == 200)
            {
              this.sucessfullyInvited = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    validEmail: function (email) {
      return this.reg.test(email);
    }
  }
}
  

</script>

<style lang="scss" scoped>

.appstore {
  width: 150px;

  @media only screen and (min-width: 992px) {
    width: 175px;
  }
}

.text-green {
  color: #1DB954;


}
  .attention {
    position: relative;
    &::before {
      content: '';
      background-color: #ed6a00;
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(182deg, #000000, #ed6a00, #000000);
      background-size: 600% 600%;

-webkit-animation: AnimationName 2s ease infinite;
-moz-animation: AnimationName 2s ease infinite;
animation: AnimationName 2s ease infinite;

@-webkit-keyframes AnimationName {
    0%{background-position:44% 0%}
    50%{background-position:57% 100%}
    100%{background-position:44% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:44% 0%}
    50%{background-position:57% 100%}
    100%{background-position:44% 0%}
}
@keyframes AnimationName {
    0%{background-position:44% 0%}
    50%{background-position:57% 100%}
    100%{background-position:44% 0%}
}
    }
  }
</style>